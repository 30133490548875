<template>
  <div class="ma-10  pa-10">
    <div v-for="x in numbers" :key="x">
      {{x}} => 
      <SmartNumber :val="-1*x" :precision="5"> </SmartNumber>
    </div>
  </div>
</template>
<script>
import SmartNumber from '@/components/SmartNumber.vue'
export default {
  name:'AlloyDesignWrought',
  components: {SmartNumber},
  data:()=>({
    numbers:[0, 25.36, 25.8,25.1,12.5,0.12,0.21321321,0.01,0.0002,0.00003658,0.000000065,12312,1232654,1231654654,1321.321321]
  })
}
</script>